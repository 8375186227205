/*
    CRON-349: Fill mode was causing headaches
  */
/*
    CRON-349: Fill mode was causing headaches
  */
.fade-in {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -ms-animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: none;
  -moz-animation-fill-mode: none;
  -ms-animation-fill-mode: none;
  -o-animation-fill-mode: none;
  animation-fill-mode: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes fadeIn {
  .fade-in 0% {
    opacity: 0; }
  .fade-in 100% {
    opacity: 1; } }

@-o-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.-flex {
  display: flex !important; }
  .-flex.-wrap {
    flex-wrap: wrap; }
  .-flex.-gap-40 {
    gap: 40px !important; }
  .-flex.-gap-35 {
    gap: 35px !important; }
  .-flex.-gap-30 {
    gap: 30px !important; }
  .-flex.-gap-25 {
    gap: 25px !important; }
  .-flex.-gap-20 {
    gap: 20px !important; }
  .-flex.-gap-15 {
    gap: 15px !important; }
  .-flex.-gap-10 {
    gap: 10px !important; }
  .-flex.-gap-5 {
    gap: 5px !important; }
  .-flex.-row {
    flex-direction: row !important;
    gap: 5px; }
  .-flex.-column {
    flex-direction: column !important;
    gap: 5px; }
  .-flex.-center {
    justify-content: center !important; }
  .-flex.-start {
    justify-content: flex-start !important; }
  .-flex.-end {
    justify-content: flex-end !important; }
  .-flex.-space-between {
    justify-content: space-between !important; }
  .-flex.-space-evenly {
    justify-content: space-evenly !important; }
  .-flex.-space-around {
    justify-content: space-around !important; }
  .-flex.-align-center {
    align-items: center !important; }
  .-flex.-align-end {
    align-items: flex-end !important; }
  .-flex.-align-start {
    align-items: start !important; }
  .-flex.-align-baseline {
    align-items: baseline !important; }

/*
    CRON-349: Fill mode was causing headaches
  */
.question.circle {
  color: #9a9a9a; }

.ui.button.disabled.primary {
  background: #efefef !important; }

.basicTableV2 .cell-wrap,
.basic-table-container .cell-wrap {
  white-space: pre;
  display: flex;
  justify-content: space-between; }

.basicTableV2 td,
.basic-table-container td {
  border: none !important; }

.red,
.error {
  color: #db2828; }

.bold {
  font-weight: bold !important; }

.noPadding {
  padding: 0 !important; }

.action-dd {
  min-width: 200px !important;
  width: 200px !important;
  height: 37px; }
  .action-dd #exportCSV {
    cursor: default !important; }
  .action-dd .item,
  .action-dd a {
    color: #252525 !important;
    width: 100%;
    display: inline-block; }
  .action-dd .active.item {
    font-weight: normal !important; }

.ui.accordion .active.content {
  background: transparent; }

.action-btns .button {
  min-width: 175px; }

.error-template {
  width: 100%;
  margin: 0;
  border-width: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center; }
  .error-template.error-template-border {
    border-width: 1px !important; }
  .error-template .header {
    text-align: center;
    justify-content: center; }
  .error-template .row {
    justify-content: center;
    text-align: center; }
  .error-template .error-message-panel {
    border: 1px dashed;
    border-radius: 0.3rem; }
  .error-template .action-btns {
    margin-top: 25px; }

.advanced-filters .dropdown {
  width: 25%; }

.dropdown .item {
  word-wrap: break-word; }
  .dropdown .item a {
    color: black !important; }

.ui.bound.bottom.sticky {
  display: none; }

.hyper-link {
  cursor: pointer; }

.basic-modal-footer {
  background: transparent !important; }

.tab-skeleton {
  display: flex;
  flex-direction: column;
  gap: 10px; }
  .tab-skeleton .tab {
    height: 300px; }
  .tab-skeleton .placeholder {
    height: 200px;
    opacity: 0.7; }
  .tab-skeleton .loader-item {
    background-color: #efefef !important;
    border-radius: 5%;
    margin-bottom: 20px !important; }
    .tab-skeleton .loader-item.skeleton-line {
      margin: 5px 0 !important; }
    .tab-skeleton .loader-item.id {
      height: 12px;
      width: 100px !important;
      display: block; }
    .tab-skeleton .loader-item.name {
      height: 24px;
      width: 150px !important; }
    .tab-skeleton .loader-item.tabs {
      display: flex;
      gap: 20px; }
      .tab-skeleton .loader-item.tabs .menu {
        width: 100px; }
    .tab-skeleton .loader-item.header {
      width: 75px;
      height: 20px; }

.display-none {
  display: none; }

.time-element {
  color: #9a9a9a; }

.mb-0 {
  margin-bottom: 0 !important; }

.data-usage-header {
  margin-left: 20px; }

.cursor-pointer {
  cursor: pointer; }

.ReactTable .rt-resizable-header {
  padding: 6px 24px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.ReactTable .rt-resizable-header-content:after {
  display: inline-block;
  position: absolute;
  top: 50%;
  height: 14px;
  width: 14px;
  color: transparent;
  content: '.';
  background-size: 14px 14px;
  background-repeat: no-repeat;
  opacity: 0.87;
  transform: translate(0, -50%);
  margin-left: 7px; }

.ReactTable .rt-th.-sort-asc .rt-resizable-header-content:after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAoUlEQVQ4je3TsQ2DMBCFYThRehJm8ADMiJDHcI9E4dJTMAEFVH8aHEFsjgZFKfKkV9n3XXU1UD0ReUT5eYhSx3Fk27bi2959GlKz9H2PiNB1Heu6lr6Q5i+hYRgQkfdmBbuGEmKMwXuPtVbDytARmaYJgGVZNCyHnHMZkqJgZ0hDbrAz1LatinxiTdMQQsiheZ6JMarIETssrICq5n+0X4NevQzZjLJzKgMAAAAASUVORK5CYII=); }

.ReactTable .rt-th.-sort-desc .rt-resizable-header-content:after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAmElEQVQ4je3TsQ2DMBCF4SNyxSCuXDAIG7inZyL3lidAovck3sHVnwqSoPhoEE3ypGtOel9zug6QK/K4RPkRKITAPM+IyOms64r3nlrr61KAAOKcQ0SYpgkty7LQ9z3GGHLObP0dKqVgrVWxdyTGuK0/IQANayDfoRamIG3oiI3jqCE6dMQU5BzasGEYSCm1kB3q+D/tbdAT3cqfLzaJ/MoAAAAASUVORK5CYII=); }

.ReactTable .rt-resizer {
  z-index: 9; }

.ReactTable .disabled .rt-td input[type='checkbox'] {
  cursor: not-allowed;
  opacity: 0.5 !important; }

.ui.modal.transition.visible.active {
  margin-top: 0px !important; }

.ui.form .row .field.error > div.dropdown {
  border: 1px solid #db2828;
  background: #fff; }
  .ui.form .row .field.error > div.dropdown:hover {
    border: 1px solid #db2828; }
  .ui.form .row .field.error > div.dropdown .text {
    background: #fff; }
  .ui.form .row .field.error > div.dropdown .menu .text {
    background: initial; }
  .ui.form .row .field.error > div.dropdown .item {
    background: #fff; }
  .ui.form .row .field.error > div.dropdown .dropdown.icon {
    color: #db2828; }

.ui.form .date-time-input.error {
  margin: 0 0 0em; }
  .ui.form .date-time-input.error input {
    border: 1px solid #db2828; }
  .ui.form .date-time-input.error .calendar {
    color: #db2828; }

.ui.input.error.labeled.right input {
  border-right: 0px solid transparent !important; }

.ui.input.error.labeled.left input {
  border-left: 0px solid transparent !important; }

.ui.input.error .ui.label {
  border: 1px solid #db2828; }

.ui.form .field.error label {
  color: initial !important; }

.ui.form .field.error input {
  background: #fff !important; }

.ui.form .error.error.error.error input:-webkit-autofill,
.ui.form .error.error.error.error input:-webkit-autofill:hover,
.ui.form .error.error.error.error input:-webkit-autofill:focus,
.ui.form .error.error.error.error input:-webkit-autofill:active {
  border: 1px solid #db2828;
  box-shadow: 0 0 0 100px #fff inset !important;
  background: #fff !important; }

.ui.form .error.error.error.error .icon {
  color: #db2828; }

.error > textarea,
.error > input {
  border: 1px solid #db2828;
  background: #fff !important; }

.ui.radio.checkbox {
  margin-right: 5px; }

.fullWidth {
  width: 100%; }

.ui.inverted.segment.footer {
  padding: 3em 2em;
  width: 100%;
  min-height: 20em;
  flex: 1; }

.ui-table {
  padding: 8em 0em; }

.ui.inverted.segment.desktop-container {
  min-height: 50em;
  padding: 1em 0em; }

.ui.inverted.segment.mobile-container {
  min-height: 35em;
  padding: 1em 0em; }

.responsive-table {
  margin-top: 10px;
  box-shadow: none;
  border-radius: 3px;
  border: 1px solid #efefef;
  overflow: hidden;
  text-align: left; }
  .responsive-table .rt-resizable-header {
    background-color: #f9fafb;
    color: #14111b;
    line-height: 2em !important;
    text-align: left;
    padding: 6px 11px !important;
    border-right: 1px solid #efefef !important;
    outline: 0;
    font-weight: bold; }
  .responsive-table .rt-resizable-header:last-child {
    border-right: none !important; }
  .responsive-table .rt-thead {
    border-bottom: 1px solid #efefef !important; }
    .responsive-table .rt-thead .rt-tr .rt-th input[type='checkbox'] {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      zoom: 1.5; }
  .responsive-table .rt-tbody {
    min-height: 316px; }
    .responsive-table .rt-tbody .rt-tr-group {
      flex: 0; }
      .responsive-table .rt-tbody .rt-tr-group .rt-tr .rt-td > input[type='checkbox'] {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        zoom: 1.5; }
  .responsive-table .rt-tbody {
    position: relative; }
  .responsive-table .rt-td {
    padding: 6px 11px !important;
    border-right: 1px solid #efefef !important;
    white-space: pre; }
  .responsive-table .rt-td:last-child {
    border-right: none !important; }
  .responsive-table .rt-tr-group {
    border-bottom: 1px solid #efefef !important;
    background-color: #fff !important; }
  .responsive-table .pagination-top {
    border-bottom: 1px solid #efefef !important; }
  .responsive-table .-pagination {
    box-shadow: none !important;
    border-top: none !important; }
    .responsive-table .-pagination .-pageSizeOptions input {
      width: 55px; }
    .responsive-table .-pagination .pagination-details,
    .responsive-table .-pagination .pagination-icons {
      display: flex; }
    .responsive-table .-pagination .pagination-icons .chevron {
      font-size: 12px;
      font-weight: bold; }
      .responsive-table .-pagination .pagination-icons .chevron.disabled {
        border: none !important; }
      .responsive-table .-pagination .pagination-icons .chevron:hover {
        opacity: 0.7;
        cursor: pointer; }
    .responsive-table .-pagination .-previous,
    .responsive-table .-pagination .-next {
      padding: 5px; }
    .responsive-table .-pagination .-center {
      justify-content: space-between !important;
      color: #9a9a9a;
      padding: 5px !important; }
      .responsive-table .-pagination .-center .icon {
        color: #252525 !important; }
  .responsive-table .-pagination .-btn {
    background: #fff !important; }
  .responsive-table .-pagination .-btn:not([disabled]):hover {
    background: #f9fafb !important;
    color: #14111b !important; }
  .responsive-table .ui.selection.dropdown.active {
    z-index: 10;
    position: absolute; }

.responsive-table-container {
  min-height: 400px; }

.no-data .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td {
  border-right: none !important; }

.no-data .rt-table .rt-tbody .rt-tr-group {
  border-bottom: none !important; }

.no-data .rt-resizable-header-content:after {
  display: none; }

.no-data .no-results-found {
  display: flex;
  justify-content: center;
  z-index: 1;
  margin-top: -240px;
  margin-bottom: 230px; }

.no-data .no-result-link {
  position: absolute;
  margin-top: 15px; }

.no-data .pagination-bottom {
  margin-top: 30px; }

.clickable .rt-tbody .rt-tr:not(.-padRow):hover {
  cursor: pointer; }

.active-icon {
  color: #21ba45; }

.inactive-icon {
  color: #767676; }

.terminated-icon {
  color: #db2828; }

.list-panel-container {
  border: none !important; }
  .list-panel-container .list-item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0.5rem 0rem; }
    .list-panel-container .list-item .header {
      text-transform: capitalize; }
    .list-panel-container .list-item .description {
      margin: 0px 0.5rem; }

.basic-table-container .ui.table tr td {
  border: none !important; }

.basic-table-container h3 i.icon {
  font-size: 14px !important; }

.text-area-input-container {
  padding-bottom: 40px; }
  .text-area-input-container h2 {
    margin-bottom: 18px !important; }
  .text-area-input-container textarea {
    width: 85%;
    padding: 14px;
    border-radius: 4px; }

.expandable-panel-container {
  max-height: 20px;
  overflow: hidden;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  -webkit-transition: max-height 0.5s;
  transition: max-height 0.5s;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%; }

.expanded {
  max-height: 500px !important;
  white-space: normal !important; }

.calculate-panel-container {
  display: flex; }
  .calculate-panel-container .calculate-btn {
    position: absolute;
    right: 40px; }

.sticky-container {
  background: transparent !important; }
  .sticky-container .sticky-header {
    overflow: hidden;
    background: #fff;
    position: relative;
    /* CRON-349: z-index is causing overlap issues, not sure why such a high z-index to start */
    z-index: 0; }
    .sticky-container .sticky-header .title {
      padding: 16px 0px !important;
      font-size: 18px !important;
      color: #0f90d0;
      font-weight: bold; }
      .sticky-container .sticky-header .title > i {
        float: none !important; }
  .sticky-container .accordion.ui .content .responsive-table-container {
    min-height: 0px !important; }
    .sticky-container .accordion.ui .content .responsive-table-container .responsive-table {
      margin-top: 0px !important; }
      .sticky-container .accordion.ui .content .responsive-table-container .responsive-table .rt-table .rt-tbody .rt-tr-group {
        line-height: 40px; }
        .sticky-container .accordion.ui .content .responsive-table-container .responsive-table .rt-table .rt-tbody .rt-tr-group .rt-td .ui.selection.dropdown,
        .sticky-container .accordion.ui .content .responsive-table-container .responsive-table .rt-table .rt-tbody .rt-tr-group .rt-td .ui.selection.dropdown .item {
          text-transform: capitalize; }
        .sticky-container .accordion.ui .content .responsive-table-container .responsive-table .rt-table .rt-tbody .rt-tr-group .rt-td .status-arrow {
          display: flex;
          justify-content: center;
          align-items: center; }

.footer-status-bar {
  background-color: #4183c4;
  z-index: 1;
  left: 15%;
  bottom: 0px;
  width: 85%;
  position: fixed; }
  .footer-status-bar .footer-status-bar-btn {
    background-color: #fff !important;
    margin: 10px 40px !important; }
  .footer-status-bar .footer-status-bar-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff; }
  .footer-status-bar.sim-terminate, .footer-status-bar.red-bar {
    background-color: #db2828; }
    .footer-status-bar.sim-terminate .footer-status-bar-text, .footer-status-bar.red-bar .footer-status-bar-text {
      position: relative;
      text-align: center;
      top: initial;
      left: initial;
      transform: initial;
      margin: 18px 40px;
      display: flex;
      justify-content: center;
      align-items: center; }

.whitelist-duplication-modal .header {
  padding: 2.5rem !important; }

.whitelist-duplication-modal .content {
  padding: 2.5rem !important; }

.whitelist-duplication-modal .actions > button {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
  margin-bottom: 2.5rem; }

.skeleton {
  display: flex;
  flex-direction: column;
  padding: 10px; }
  .skeleton .skeleton-line {
    background: #eee;
    border-radius: 4px;
    height: 15px;
    margin-bottom: 0.75rem;
    width: 98%;
    margin-left: 21px;
    position: relative;
    overflow: hidden !important; }
    .skeleton .skeleton-line::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 40%, rgba(255, 255, 255, 0.5) 80%, rgba(255, 255, 255, 0));
      animation: shimmer 2s infinite; }
  .skeleton .skeleton-header {
    width: 100%;
    height: 20px;
    margin-left: initial; }

@keyframes shimmer {
  100% {
    transform: translateX(100%); } }

#loader {
  background-color: #fff;
  font-family: Arial !important; }
  #loader.full-screen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1000;
    opacity: 0.8; }
  #loader.loader-bold .ui.loader:before {
    border-color: rgba(0, 0, 0, 0.5); }
  #loader.loader-bold .loader-content {
    color: black; }
    #loader.loader-bold .loader-content h2 {
      margin: 0; }

.beta-badge {
  background: #21ba45;
  color: #fff;
  border-radius: 4px;
  font-weight: 700;
  padding: 3px 9px;
  font-size: 13px;
  margin: 0 5px; }

.confirm-billing-group-rates-changes .expandable-table .status-arrow {
  text-align: center; }

.confirm-billing-group-rates-changes .try-again {
  cursor: pointer;
  color: #0f90d0; }

.confirm-billing-group-rates-changes .footer-status-bar {
  left: 15%;
  bottom: 0px;
  width: 85%;
  position: fixed; }

.error-label {
  display: flex;
  align-items: center;
  margin: 5px 0px; }
  .error-label i {
    margin-right: 5px;
    font-size: 14px; }
  .error-label i,
  .error-label .error-label-content {
    color: #db2828; }

.error-message-panel {
  background-color: #fafafa;
  padding: 20px 30px;
  text-align: left; }

.upload-file-container {
  border-radius: 3px;
  padding: 11px 19.5px; }

.export-csv-btn {
  padding: 0 !important;
  display: flex !important;
  flex-direction: row; }
  .export-csv-btn > a {
    padding: 11px 19.5px; }
  .export-csv-btn i {
    margin-right: 5px; }

.export-csv-lazy .export-csv-lazy-btn {
  padding: 11px 19.5px !important; }

.export-csv-lazy i {
  margin-right: 5px; }

.view-mode-button {
  height: 30px; }
  .view-mode-button .button {
    padding-top: 4px !important;
    padding-bottom: 4px !important; }
    .view-mode-button .button:first-child {
      border-right: none !important; }
    .view-mode-button .button:not(.isClicked) {
      background-color: #fff !important;
      border: solid 1px #d4d4d5 !important;
      color: #000 !important; }

.table-by-categories .empty {
  border-left: none !important; }

.table-by-categories .category-cell {
  width: 400px; }

.table-by-categories .sub-category-cell {
  display: flex;
  flex-direction: column;
  width: 400px; }

.table-by-categories .question {
  margin-left: 5px; }

.table-by-categories .deselect-all {
  float: right;
  margin-right: 30px;
  text-decoration: underline;
  color: #4d9dc6;
  cursor: pointer; }

.table-by-categories .ui.checkbox {
  margin-right: 10px; }

.table-by-categories .itemRow-row {
  display: flex;
  flex-direction: row;
  margin: 10px 0px; }

.selected-option-color .text {
  color: #252525 !important; }

.selected-option-color.search-dropdown-error {
  border-color: #db2828 !important; }

.selected-option-color.text-default .dropdown.icon {
  color: #9a9a9a !important; }

.dropdown-sticky-container {
  position: sticky;
  top: 0;
  z-index: 101;
  padding: 11px;
  display: grid;
  background-color: white;
  border-bottom: 1px solid #f3f0f0; }
  .dropdown-sticky-container .search.icon {
    background-color: #0f90d0; }

.dp_container {
  width: 700px;
  cursor: pointer; }
  .dp_container .divider {
    margin: 0 !important; }
  .dp_container .info {
    color: #9a9a9a;
    position: absolute;
    bottom: 5px;
    font-size: 11px; }
  .dp_container .today {
    color: #252525; }
  .dp_container .presets {
    display: flex;
    justify-content: space-between;
    margin: 5px 0px; }
  .dp_container .datetime-input-container {
    /* Chrome, Safari, Edge, Opera */
    /* Firefox */
    border: 1px solid #efefef;
    border-radius: 5px;
    width: 100%;
    height: 35px;
    margin-top: 10px;
    padding: 0; }
    .dp_container .datetime-input-container.wl-error {
      border-color: #db2828; }
      .dp_container .datetime-input-container.wl-error:focus-within, .dp_container .datetime-input-container.wl-error:focus, .dp_container .datetime-input-container.wl-error:hover {
        border-color: #db2828; }
      .dp_container .datetime-input-container.wl-error input {
        color: #db2828; }
        .dp_container .datetime-input-container.wl-error input::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #db2828;
          opacity: 0.5;
          /* Firefox */ }
        .dp_container .datetime-input-container.wl-error input:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #db2828; }
        .dp_container .datetime-input-container.wl-error input:-ms-input-placeholder {
          /* Microsoft Edge */
          color: #db2828; }
    .dp_container .datetime-input-container input::-webkit-outer-spin-button,
    .dp_container .datetime-input-container input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .dp_container .datetime-input-container input[type='number'] {
      -moz-appearance: textfield;
      appearance: textfield; }
    .dp_container .datetime-input-container .input-container {
      display: flex;
      flex-grow: 1;
      align-items: center;
      padding: 7px; }
      .dp_container .datetime-input-container .input-container .ui.input.disabled {
        border: none !important; }
    .dp_container .datetime-input-container:hover, .dp_container .datetime-input-container:focus-within {
      border-color: #0f90d0; }
    .dp_container .datetime-input-container input {
      border: none !important;
      padding: 0;
      height: 15px;
      width: 35px;
      padding: 5px !important; }
    .dp_container .datetime-input-container .year input {
      width: 45px; }
    .dp_container .datetime-input-container input#minute {
      width: 100%; }
  .dp_container .ip_container {
    padding: 0; }
  .dp_container .inRange,
  .dp_container .today {
    background: rgba(15, 144, 208, 0.3); }
  .dp_container.rangePicker .inRange {
    border-radius: 0px; }
    .dp_container.rangePicker .inRange .dp_calender_items:not(.selected) {
      border-radius: 0px;
      background: transparent;
      color: #252525; }
  .dp_container.rangePicker .ip_container {
    padding: 10px 0; }
  .dp_container.rangePicker .dashed-divider {
    border: none;
    border-bottom: 2px dashed #efefef;
    margin: 15px 0px; }
  .dp_container.rangePicker .dp_cal_items_wrap.start {
    background: linear-gradient(90deg, #fff 50%, rgba(15, 144, 208, 0.3) 50%); }
  .dp_container.rangePicker .dp_cal_items_wrap.end {
    background: linear-gradient(90deg, rgba(15, 144, 208, 0.3) 50%, #fff 50%); }

#dtp_popup {
  border: 1px solid #0f90d0; }

.dp_popup {
  padding: 0px !important; }

.dp_view {
  display: flex;
  width: 200px;
  justify-content: space-between;
  border: 1px solid #efefef;
  border-radius: 5px; }
  .dp_view.active, .dp_view:hover {
    border: 1px solid #0f90d0; }
  .dp_view.rangePicker {
    width: 350px; }
  .dp_view .dp_view_header {
    border-radius: 5px;
    padding: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between; }
    .dp_view .dp_view_header .dp_cal_icon.placeholder {
      color: #9a9a9a; }

.dp_months_nav {
  padding-top: 5px;
  height: 50px;
  background: #f9fafb; }
  .dp_months_nav .dp_current_month {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px; }

.dp_calender_day,
.dp_months_nav {
  font-weight: bold; }

.arrows {
  height: 20px;
  width: 20px;
  line-height: 17px; }

.dp_selections {
  width: 100%;
  display: flex; }

.dp_dates,
.dp_time {
  width: 50%; }

.dp_time {
  position: relative;
  border-left: 1px solid #efefef;
  padding: 20px; }
  .dp_time .label {
    font-weight: bold;
    padding: 2px 0px; }

.dp_calender_day {
  background: #f9fafb;
  text-align: center;
  height: 30px;
  width: 30px; }

.dp_month_header,
.dp_calender {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  row-gap: 10px;
  padding-bottom: 5px; }

.dp_calender {
  justify-items: center;
  margin-top: 30px; }
  .dp_calender .dp_cal_items_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%; }

.dp_month_header {
  justify-items: center;
  background: #f9fafb; }

.dp_calender_items {
  border-radius: 50%;
  height: 30px;
  width: 30px; }
  .dp_calender_items.today {
    border-radius: 50%; }
  .dp_calender_items.dp_disabled_days {
    pointer-events: none;
    user-select: none;
    opacity: 50%;
    border: none !important; }

.dp_calender_day,
.dp_calender_items {
  display: flex;
  justify-content: center;
  align-items: center; }
  .dp_calender_day.selected,
  .dp_calender_items.selected {
    background: #0f90d0 !important;
    opacity: 1 !important;
    color: #fff; }

.dp_footer {
  padding: 10px;
  display: flex;
  justify-content: space-between; }
  .dp_footer .clear:hover {
    text-decoration: underline;
    text-underline-offset: 2px; }
  .dp_footer .hyper-link.btn-disabled {
    color: #9a9a9a !important;
    border: none !important;
    cursor: not-allowed;
    opacity: 50%;
    user-select: none;
    text-decoration: none; }

.custom-column-container {
  width: 100%;
  padding: 15px 0 !important;
  display: flex;
  flex-direction: column; }

.column-view-btn {
  height: 40px;
  width: 120px;
  padding: 1px !important; }
  .column-view-btn .fa-line-columns {
    margin-right: 10px; }

#column-settings-container {
  position: fixed;
  top: 60px;
  right: -350px;
  width: 350px;
  background: #fff;
  z-index: 999;
  padding: 0px !important;
  transition: 0.5s; }
  #column-settings-container .sidebar-content {
    height: calc(100vh - 45px);
    box-sizing: border-box; }
    #column-settings-container .sidebar-content .tabs-wrap.disabledtabs .menu .item {
      user-select: none;
      color: #9a9a9a;
      cursor: not-allowed; }
  #column-settings-container.open {
    right: 0 !important; }
    #column-settings-container.open .footer-wrap {
      position: absolute;
      bottom: 10px;
      right: 0 !important; }
  #column-settings-container .disabled {
    border: none !important; }
  #column-settings-container .column-settings-wrap,
  #column-settings-container .saved-view-wrap {
    position: relative;
    padding-top: 20px !important;
    height: calc(100% - 100px); }
    #column-settings-container .column-settings-wrap .scroll,
    #column-settings-container .saved-view-wrap .scroll {
      height: calc(100% - 70px);
      overflow-x: auto; }
    #column-settings-container .column-settings-wrap .description,
    #column-settings-container .saved-view-wrap .description {
      padding: 0 15px; }
    #column-settings-container .column-settings-wrap .info-text,
    #column-settings-container .saved-view-wrap .info-text {
      padding: 0 15px 20px; }
      #column-settings-container .column-settings-wrap .info-text i,
      #column-settings-container .saved-view-wrap .info-text i {
        padding-left: 5px; }
    #column-settings-container .column-settings-wrap .column-list ul,
    #column-settings-container .saved-view-wrap .column-list ul {
      list-style: none;
      padding: 0; }
    #column-settings-container .column-settings-wrap .column-list .column-list-item,
    #column-settings-container .saved-view-wrap .column-list .column-list-item {
      height: 30px;
      margin-bottom: 5px; }
      #column-settings-container .column-settings-wrap .column-list .column-list-item .content,
      #column-settings-container .saved-view-wrap .column-list .column-list-item .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 15px; }
      #column-settings-container .column-settings-wrap .column-list .column-list-item .disabledIcon,
      #column-settings-container .saved-view-wrap .column-list .column-list-item .disabledIcon {
        user-select: none;
        opacity: 0.5; }
      #column-settings-container .column-settings-wrap .column-list .column-list-item:hover,
      #column-settings-container .saved-view-wrap .column-list .column-list-item:hover {
        background: #efefef; }
        #column-settings-container .column-settings-wrap .column-list .column-list-item:hover .action-icons:hover,
        #column-settings-container .saved-view-wrap .column-list .column-list-item:hover .action-icons:hover {
          background: #efefef; }
      #column-settings-container .column-settings-wrap .column-list .column-list-item .icon,
      #column-settings-container .saved-view-wrap .column-list .column-list-item .icon {
        padding: 5px;
        border-radius: 5px; }
        #column-settings-container .column-settings-wrap .column-list .column-list-item .icon:hover,
        #column-settings-container .saved-view-wrap .column-list .column-list-item .icon:hover {
          background: #d9d9d9; }
        #column-settings-container .column-settings-wrap .column-list .column-list-item .icon i,
        #column-settings-container .saved-view-wrap .column-list .column-list-item .icon i {
          padding: 4px; }
      #column-settings-container .column-settings-wrap .column-list .column-list-item .checkbox.checked,
      #column-settings-container .saved-view-wrap .column-list .column-list-item .checkbox.checked {
        font-weight: bold; }
      #column-settings-container .column-settings-wrap .column-list .column-list-item .checkbox.read-only > label::after,
      #column-settings-container .saved-view-wrap .column-list .column-list-item .checkbox.read-only > label::after {
        opacity: 0.5; }
  #column-settings-container .view-name-input {
    width: 100%; }
  #column-settings-container .secondary-text {
    color: #9a9a9a; }
  #column-settings-container .component-close-btn,
  #column-settings-container .footer-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    width: 350px;
    padding: 10px;
    border-top: 1px solid #efefef; }
    #column-settings-container .component-close-btn.fixed,
    #column-settings-container .footer-wrap.fixed {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 350px !important; }
  #column-settings-container .header-wrap {
    padding: 10px; }
  #column-settings-container .bold {
    font-weight: bold; }
  #column-settings-container .header,
  #column-settings-container .divider {
    margin: 0px; }
  #column-settings-container .comp-header b {
    font-size: 13px;
    color: #9a9a9a; }
  #column-settings-container .comp-header .icon {
    float: right;
    margin: 0; }
  #column-settings-container .menu-tab {
    padding: 0px; }
  #column-settings-container .tabs-wrap {
    overflow-y: auto;
    overflow-x: hidden;
    height: 99%;
    position: relative; }
    #column-settings-container .tabs-wrap .menu {
      text-align: center;
      margin: 0 !important;
      padding: 0px 15px; }
      #column-settings-container .tabs-wrap .menu .item {
        justify-content: center;
        color: #0f90d0;
        font-size: 13px !important;
        font-weight: bold !important;
        width: 50%;
        padding: 10px; }
        #column-settings-container .tabs-wrap .menu .item.active {
          color: #252525;
          border-bottom: 2px solid #252525; }
  #column-settings-container .segment {
    border: none; }
  #column-settings-container .link-style-btn:hover {
    opacity: 0.7;
    cursor: pointer; }

#blured-bg {
  height: 100vh;
  width: 100vw;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  display: none;
  z-index: 1;
  opacity: 0.7;
  background-color: white; }
  #blured-bg.show {
    display: block; }

.saved-view-wrap {
  position: relative; }
  .saved-view-wrap .saved-view-list {
    height: calc(100% - 20px);
    position: absolute;
    transition: 0.5s;
    width: 100%;
    right: 350px;
    opacity: 0; }
    .saved-view-wrap .saved-view-list.show {
      right: 0;
      opacity: 1; }
    .saved-view-wrap .saved-view-list .secondary-text {
      padding: 5px 15px; }
  .saved-view-wrap .column-list-item .radio.checked {
    font-weight: bold; }
  .saved-view-wrap .confirmation-view {
    position: absolute;
    width: 100%;
    padding: 15px;
    left: 350px;
    opacity: 0;
    transition: 0.5s; }
    .saved-view-wrap .confirmation-view.show {
      left: 0;
      opacity: 1; }
    .saved-view-wrap .confirmation-view .section {
      padding: 10px 0px; }
    .saved-view-wrap .confirmation-view .footer-wrap {
      position: relative !important;
      width: 330px !important;
      padding: 10px 0px !important;
      border: none !important; }

.ratio-progress-bar .unit, .ratio-progress-bar .startUnit, .ratio-progress-bar .endUnit {
  color: #9a9a9a; }

.ratio-progress-bar .endUnit {
  float: right; }

.ratio-progress-bar .no-data-used .bar {
  min-width: 0 !important; }

.simple-dropdown .dropdown {
  height: 35px; }

.simple-dropdown h5 {
  margin-bottom: 5px; }

.simple-dropdown .default.text {
  color: #9a9a9a !important; }

.simple-dropdown .ui.multiple.dropdown {
  height: auto; }

.simple-dropdown .ui.action-dd .disabled.item {
  pointer-events: none !important; }

.import-csv i {
  margin-right: 5px; }

.import-csv .selected-file-name {
  color: #0f90d0;
  margin-left: 5px; }

.tooltipContainer {
  width: 350px; }
  .tooltipContainer .statusItem {
    padding: 10px 5px; }

.country-table-header {
  padding: 8px 10px !important;
  font-size: 18px !important;
  color: #0f90d0;
  font-weight: bold; }

.export-import-csv-header .ui.button.export-csv-btn {
  margin-right: 20px; }

.expandable-table .ReactTable {
  border: none; }
  .expandable-table .ReactTable .rt-tr-group {
    line-height: 40px; }
  .expandable-table .ReactTable .rt-td {
    font-size: 18px;
    color: #0f90d0;
    font-weight: bold;
    padding: 8px 10px; }
    .expandable-table .ReactTable .rt-td.rt-expandable {
      border: none !important; }
  .expandable-table .ReactTable .sticky-container .header-table {
    border-bottom: none !important;
    border-bottom-right-radius: 0rem;
    border-bottom-left-radius: 0rem; }
  .expandable-table .ReactTable .sticky-container .content-table {
    margin-top: 0;
    border-top: none !important;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem; }

.checkbox-list .checkbox-list-header {
  padding: 10px;
  border: solid 1px #e2e2e2;
  border-bottom: none;
  background: #f9fafb; }

.checkbox-list .items-container {
  border: solid 1px #e2e2e2;
  overflow-y: auto;
  height: 300px; }
  .checkbox-list .items-container .empty-text {
    margin: 20px;
    text-align: center; }

.checkbox-list .items-list {
  padding: 10px; }

.checkbox-list .checkbox-list-label {
  display: inline-block;
  margin-left: 10px; }

#task-list-container {
  position: relative; }
  #task-list-container .tasks {
    position: absolute;
    z-index: 100;
    width: 100%; }
  #task-list-container .card-header {
    display: flex;
    justify-content: space-between; }
  #task-list-container .toggle-icon {
    margin-top: -2%; }
  #task-list-container .progress-bar {
    margin-bottom: 2%; }

.inline-error-with-reload-text {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: rgba(33, 33, 33, 0.45); }

.inline-error-reload-link {
  cursor: pointer;
  color: #4890d1; }

.subsection-text {
  color: #252525; }

#error-template-400 {
  text-align: center;
  width: 800px;
  margin: 0 auto; }
  #error-template-400 .header {
    color: #db2828;
    margin: 40px;
    font-size: 32px; }
    #error-template-400 .header .fa-exclamation-circle {
      margin-right: 15px; }
  #error-template-400 .body {
    margin: 30px 0; }
  #error-template-400 .footer {
    display: flex;
    justify-content: center;
    align-items: center; }

#error-template-403 {
  text-align: center;
  width: 550px;
  margin: 0 auto; }
  #error-template-403 .header {
    color: #db2828;
    margin: 40px;
    font-size: 32px; }
    #error-template-403 .header .fa-exclamation-circle {
      margin-right: 15px; }
  #error-template-403 .body {
    margin: 30px 0; }

#error-template-404 {
  text-align: center;
  width: 500px;
  margin: 0 auto; }
  #error-template-404 .header {
    color: #db2828;
    margin: 40px;
    font-size: 32px; }
    #error-template-404 .header .fa-exclamation-circle {
      margin-right: 15px; }
  #error-template-404 .body {
    margin: 30px 0; }

#error-template-500 {
  text-align: center;
  width: 500px;
  margin: 0 auto; }
  #error-template-500 .header {
    color: #db2828;
    margin: 40px;
    font-size: 32px; }
    #error-template-500 .header .fa-exclamation-circle {
      margin-right: 15px; }
  #error-template-500 .body {
    margin: 30px 0; }

.error-template {
  text-align: center;
  margin: 0 auto; }
  .error-template .header {
    color: #db2828;
    margin: 40px;
    font-size: 32px; }
    .error-template .header .fa-exclamation-circle {
      margin-right: 15px; }
  .error-template .body {
    margin: 30px 0; }
  .error-template .align-template-center {
    text-align: center;
    margin: 0 auto;
    width: 80%; }
    .error-template .align-template-center .error-message-panel {
      margin: 20px; }
  .error-template .main-content-box .main-content {
    height: 200px !important;
    overflow: auto;
    width: 100%; }

.error-template-border {
  border-radius: 3px;
  border: solid 1px #d4d4d5;
  padding-bottom: 30px; }

.basic-success-template {
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 400px; }
  .basic-success-template .header {
    margin-bottom: 10px; }
  .basic-success-template .body {
    margin-bottom: 30px; }
  .basic-success-template .footer {
    text-align: center; }

.click-to-copy-template .main-content-box {
  background-color: rgba(154, 154, 154, 0.1);
  min-height: 4.6rem;
  border: 1px dashed;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 20px 0px; }

.click-to-copy-box {
  display: inline-block;
  border-radius: 3px;
  border: 1px solid;
  margin: 2px; }
  .click-to-copy-box .content-to-copy {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 10px 1px 10px;
    display: inline-block; }
  .click-to-copy-box .click-icon {
    float: right;
    padding: 5px; }
  .click-to-copy-box .click-icon:hover {
    cursor: copy;
    background-color: #e9e9ea;
    cursor: pointer; }
  .click-to-copy-box .bold-icon {
    font-weight: bold !important; }

#filter-search-bar .search-input .dropdown {
  border-radius: 0.3rem 0 0 0.3rem; }

#filter-search-bar .ui.form .field > .selection.dropdown.input-control {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px; }

#filter-search-bar .ui.input > input {
  border-radius: 0px; }
  #filter-search-bar .ui.input > input.curvedBorders, #filter-search-bar .ui.input > input.curvedBorders:focus {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem; }

#filter-search-bar .ui.input > input:focus {
  border-radius: 0px; }

#filter-search-bar .ui.input > button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px; }

#filter-search-bar .flex-box {
  display: flex; }

#filter-search-bar .filter {
  flex-shrink: 2;
  min-width: 188px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none; }

#filter-search-bar-v2 .dropdown,
#filter-search-bar-v2 .buttom,
#filter-search-bar-v2 .input {
  height: 37px; }

#filter-search-bar-v2 .input-control {
  border-radius: 0 0.3rem 0.3rem 0 !important; }
  #filter-search-bar-v2 .input-control input {
    border-radius: 0 0.3rem 0.3rem 0 !important; }

#filter-search-bar-v2 .action-dd {
  height: 40px; }

#filter-search-bar-v2 .filter {
  min-width: 150px;
  border-radius: 0.3rem 0 0 0.3rem; }

#filter-search-bar-v2 .number-input,
#filter-search-bar-v2 .text-input {
  margin: 0; }

#filter-search-bar-v2 .number-input,
#filter-search-bar-v2 .input-control {
  width: 400px; }

#filter-search-bar-v2 .iccid-range-input .input-control {
  padding: 0 !important;
  border: 0px;
  margin: 0px !important;
  display: flex;
  align-items: baseline;
  justify-content: space-evenly; }
  #filter-search-bar-v2 .iccid-range-input .input-control input {
    border-radius: 0.3rem !important; }

#filter-search-bar-v2 .iccid-range-input .rangeError {
  margin: 0 10px !important; }

.amount-input h5 {
  margin: 0 !important; }

.amount-input .inner {
  display: inline-flex;
  align-items: center; }
  .amount-input .inner.hasRightLabel .right.ui.label {
    border-left-width: 0px !important; }
  .amount-input .inner.hasRightLabel input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important; }
  .amount-input .inner.hasLeftLabel .left.ui.label {
    border-right-width: 0px !important; }
  .amount-input .inner.hasLeftLabel input {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important; }
  .amount-input .inner .ui.label,
  .amount-input .inner .ui input {
    height: 37px !important; }
  .amount-input .inner .ui.label {
    margin: 0;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border: 1px solid #dededf;
    background-color: #fff; }
    .amount-input .inner .ui.label.left {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .amount-input .inner .ui.label.right {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    .amount-input .inner .ui.label.wl-error {
      border: 1px solid #db2828; }
  .amount-input .inner .ui input {
    border-radius: 5px; }
    .amount-input .inner .ui input:focus, .amount-input .inner .ui input:active {
      border-color: #dededf; }
  .amount-input .inner .error {
    color: #db2828; }
    .amount-input .inner .error input {
      border: 1px solid #db2828; }
    .amount-input .inner .error.label {
      color: #db2828; }

.text-input h5 {
  margin-bottom: 5px; }

.text-input p {
  margin: 0 0 5px 0; }

.text-input .ui.input.error input {
  height: 35px !important;
  border: 1px solid #db2828;
  color: #db2828; }

.iccid-input .ui.input.error input {
  height: 35px !important;
  border: 1px solid #db2828;
  color: #db2828; }

.iccid-input .length-text {
  color: #9a9a9a;
  font-size: 11px;
  text-align: right; }

.iccid-input #list textarea {
  padding: 10px;
  border-color: #efefef;
  border-radius: 4px !important;
  height: 100px;
  max-height: 100px;
  overflow: auto;
  resize: none;
  width: 100%; }
  .iccid-input #list textarea.error {
    color: #db2828;
    border: 1px solid #db2828; }

.iccid-input-v2 .ui.input.error input {
  border: 1px solid #db2828;
  color: #db2828; }

.iccid-input-v2 .length-text {
  color: #9a9a9a;
  font-size: 11px;
  text-align: right; }

.number-input {
  margin-right: 5px;
  overflow: hidden; }

.ui.form textarea.error, .ui.form textarea.error:focus {
  color: #db2828;
  border: 1px solid #db2828; }

.text-area-input textarea {
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px solid #efefef;
  min-height: 100px;
  border-radius: 0.30769231rem; }
  .text-area-input textarea.error, .text-area-input textarea.error:focus {
    color: #db2828;
    border: 1px solid #db2828; }

.cronus-button.button {
  height: 37px; }
  .cronus-button.button .icon {
    margin: 0; }
    .cronus-button.button .icon.pencil {
      margin-right: 5px; }

.cronus-button.disabled {
  opacity: 0.7;
  cursor: not-allowed;
  user-select: none; }

.cronus-icon .hover-effect {
  cursor: pointer;
  width: fit-content;
  margin-right: 0; }
  .cronus-icon .hover-effect::before {
    padding: 8px 12px;
    border-radius: 24px; }
  .cronus-icon .hover-effect:hover::before {
    background-color: #efefef !important; }
